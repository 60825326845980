import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
// import { ethers } from 'ethers';

const propTypes = { ...SectionProps.types }
const defaultProps = { ...SectionProps.defaults }

const getFaceLink = () => {
  const telegramLink = "https://t.me/huancapital"
  return telegramLink
}
const getNextMonth = () => {
  const month = new Date().getMonth() + 2
  if (parseInt(month) > 12) {
    return 1
  }
  return month
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const bannerUrl = 'https://i.ibb.co/RhkHXv8/Screenshot-2023-07-02-at-15-09-29.png'
  const bannerUrl2 = 'https://i.ibb.co/8K0GyNb/Screenshot-2023-07-06-at-15-16-01.png'
  const bannerUrl3 = 'https://i.ibb.co/RzptzGL/Screenshot-2023-07-06-at-21-38-17.png'

  // const [account, setAccount] = useState(null);

  // const connectWallet = async () => {
  //   if (typeof window.ethereum !== 'undefined') {
  //     try {
  //       // Request account access
  //       const [selectedAccount] = await window.ethereum.request({ method: 'eth_requestAccounts' });
  //       setAccount(selectedAccount);

  //       // Set up a provider using ethers
  //       const provider = new ethers.BrowserProvider(window.ethereum);

  //       console.log("################## Connected account:", selectedAccount);
  //       const signer = await provider.getSigner();
  //       console.log("Signer provider:", signer.provider);
  //     } catch (error) {
  //       console.error("Error connecting to MetaMask:", error);
  //     }
  //   } else {
  //     alert("MetaMask is not installed. Please install MetaMask and try again.");
  //   }
  // };

  // const registerCourseWithETH = async () => {
  //   if (!account) {
  //     alert("Please connect your wallet first.");
  //     return;
  //   }

  //   try {
  //     // Create a new provider and signer
  //     const provider = new ethers.BrowserProvider(window.ethereum);
  //     const signer = await provider.getSigner();

  //     // Define the transaction object
  //     const tx = {
  //       to: "0xRecipientAddress", // Replace with the recipient's address
  //       value: ethers.parseEther("0.01"), // Amount of ETH to send
  //       gasLimit: 21000, // Set gas limit
  //       gasPrice: await provider.getGasPrice(), // Optional: set a specific gas price
  //     };

  //     // Sign and send the transaction
  //     const transactionResponse = await signer.sendTransaction(tx);
  //     console.log("Transaction Response:", transactionResponse);

  //     // Wait for the transaction to be mined
  //     const receipt = await transactionResponse.wait();
  //     console.log("Transaction Receipt:", receipt);

  //     alert("Transaction successful!");
  //   } catch (error) {
  //     console.error("Error registering course:", error);
  //     alert("Transaction failed.");
  //   }
  // };

  // const renderConnecteButton = () => {
  //   if (!account) {
  //     return (<Button onClick={connectWallet} tag="a" color="primary" wideMobile>
  //       <strong>
  //         Connect Wallet
  //   </strong>
  //     </Button>)
  //   }
  //   return (
  //     <Button onClick={registerCourseWithETH} tag="a" color="primary" wideMobile>
  //       <strong>
  //         Payment 0.4 ETH
  //     </strong>
  //     </Button>
  //   )
  // }

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Học Giao Dịch Tiền Điện Tử
            </h1>
            <div className="container-xs">
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  {/* <Button tag="a" color="primary" wideMobile target='_blank' href={getFaceLink()}>
                    Hoàn Tiền 100% - Học Thử <strong> (Miễn Phí)</strong>
                  </Button> */}
                </ButtonGroup>
                {/* <ButtonGroup>
                  <label tag="a" color="primary" wideMobile target='_blank' href={getFaceLink()}>
                    Cách đăng kí: Nhắn tin cho Fanpage "Đăng kí khoá học Future"
                  </label>
                </ButtonGroup> */}
              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <Image className="has-shadow" src={bannerUrl} alt='banner' width={896} height={304} />
          </div>
          <br></br>
          <div>
              <img className="makesure-class" src="https://i.ibb.co/P9qv986/Screenshot-2024-10-25-at-11-19-11-1.png">
              </img>
            </div>
            <br></br>
          <ButtonGroup>
              <Button tag="a" color="yellow" wideMobile target='_blank' href="https://t.me/huancapital">
                <strong>Hoàn Tiền 100%</strong>
              </Button>
              {/* {
                renderConnecteButton()
              } */}
            </ButtonGroup>
          {/* <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <Image className="has-shadow" src={bannerUrl2} alt='banner' width={896} height={304} />
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <Image className="has-shadow" src={bannerUrl3} alt='banner' width={896} height={304} />
          </div> */}
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;